import { Meta, Title } from '@solidjs/meta';
import { Container, Heading, HorizontalRule, Page, Section } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { Hero } from '../../components/hero/photo';
import { Carousel } from '../../components/carousel';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonAccessLaunchParty() {
	return (
		<>
			<Title>Troon Access Launch Party &amp; Member/Guest | Troon</Title>
			<Meta
				name="description"
				content="Get ready for an unforgettable night at the official Troon Access Launch Party & Member/Guest at Grass Clippings Rolling Hills in Phoenix, AZ, on February 4th!"
			/>

			<Hero src="https://images.ctfassets.net/rdsy7xf5c8dt/5kBIAIs2mYeMvGE2LiRRWx/9ac12d6027b35a99d9f0ff6f363ff639/member-guestbackground.jpg">
				<div class="flex flex-col items-center gap-4 py-12">
					<Heading as="h1">
						<img
							src="https://images.ctfassets.net/rdsy7xf5c8dt/3v71xwisRqVNYbk9XRnup4/531da35e1804b0443e836117051ba2c7/member-guestlogo.svg"
							alt=""
							class="max-w-96"
						/>
						<span class="sr-only">Troon Access Launch Party &amp; Member/Guest</span>
					</Heading>
				</div>
			</Hero>

			<Container size="small">
				<Page>
					<Section>
						<NoHydration>
							<Section>
								<p>
									Thank you to all who attended the 1st annual Troon Access Member Guest Tournament and Launch Party at
									Grass Clippings Rolling Hills! We had a great time hosting the over 300 members who came to the event
									and the 90 players who competed for a packed list of prizes.
								</p>

								<p>
									We ended up having a 6-way tie for first place at 3 under par! After the chip-off, the placing was as
									follows:
								</p>

								<ol>
									<li>1st Place: Mogg/ Jensen - $1000 per player</li>
									<li>2nd Place: Tollefson/ Keller -$750 per player</li>
									<li>3rd Place: Gassaway/ Bollin - $500 per player</li>
									<li>4th Place: Howes/ Greatrex - $300 per player</li>
									<li>
										Last Place: McCarthy/ McCarthy - Free Golf Lesson from Keith Bennet (Best Young Teacher 2024-2025)
									</li>
								</ol>

								<p>
									In addition to taking home the 1st Place prize of $1,000 each, a{' '}
									<b>huge congratulations to Jeff Jensen for acing #9 and winning an additional $10,000!</b>
								</p>

								<ol>
									<li>#3 Closest to the Pin: Waste Management Tickets - Roderick Tung 3’5”</li>
									<li>#4 Closest to the Pin: Salt & Stone Package- Ryan Tollesfson 1’2”</li>
									<li>#6 Closest to the Pin: Urban Golf Performance Package- Bo Panico 24”</li>
									<li>#9 Hole in One Prize: $10,000- Jeff Jensen </li>
									<li>#10 Closest to the Pin: Rad Golf Package- Christine Schenk 6’</li>
									<li>#12 Closest to the Pin: Full Swing Launch Monitor- Bryan Baker 5’</li>
									<li>#18 Closest to the Pin: Sunday Bag - Geoff Howes 3’</li>
								</ol>

								<p>
									This is the first of several more exclusive Troon Access events this year. More information to come
									and we look forward to seeing you later this year!{' '}
								</p>

								<HorizontalRule />

								<Heading as="h2" size="h4">
									About this member-exclusive event:
								</Heading>

								<ul class="flex flex-col gap-4">
									<li>
										<b class="mb-2 block text-lg">5pm-8pm Launch Party</b>
										<ul class="flex list-disc flex-col gap-1 ps-8">
											<li>Complimentary food & drinks (each attendee will get 2 drink tickets)</li>
											<li>
												Beat The Pro competition featuring PGA Tour Winner <b>Parker McLachlin</b>
											</li>
											<li>
												Short Game Clinic with PGA Tour player <b>Erik Van Rooyen</b>
											</li>
											<li>Putting challenge to win a custom-fit Taylormade putter</li>
											<li class="font-semibold">Free entry for any Troon Access member and 1 guest</li>
										</ul>
									</li>
									<li>
										<b class="mb-2 block text-lg">7pm Shotgun Start Member/Guest</b>
										<ul class="flex list-disc flex-col gap-1 ps-8">
											<li>Green fees, cart, food & drinks included (each player will get 2 drink tickets)</li>
											<li>Tee Gifts for each player</li>
											<li>$75,000+ in hole-in-one and closest-to-the-pin prizes</li>
											<li class="font-semibold">The tournament field is now full</li>
										</ul>
									</li>
									<li>
										<b class="mb-2 block text-lg">8pm-midnight Late Night with Troon Access</b>
										<ul class="flex list-disc flex-col gap-1 ps-8">
											<li>Complimentary food</li>
											<li>Music featuring DJ Munition</li>
											<li>Cash bar</li>
											<li>Follow the competition and cheer on competitors</li>
											<li>Surprise guests</li>
											<li>Awards</li>
										</ul>
									</li>
								</ul>
							</Section>
						</NoHydration>

						<Carousel
							images={[
								{
									url: 'https://images.ctfassets.net/rdsy7xf5c8dt/3hN6Oa6yH7hKcvEqoup02H/0dac8e30eccd6e8d1e6e213fd6291c56/Grass_Clippings_-_2024_GL_Championship_-_Round_1-17.jpg?h=250',
								},
								{
									url: 'https://images.ctfassets.net/rdsy7xf5c8dt/1gt1rRScJT2CZth7AwaTFl/6ea56bf675043997e6106fe4feaae735/drone_photo_.jpg?h=250',
								},
							]}
						/>

						<NoHydration>
							<Section>
								<Heading as="h2" size="h4">
									More about the Event:
								</Heading>
								<ul class="flex flex-col gap-4">
									<li>
										<b class="mb-2 block text-lg">Beat The Pro:</b>
										<ul class="flex list-disc flex-col gap-1 ps-8">
											<li>
												Take your shot at beating 9-time PGA Tour winner Matt Kuchar in a closest-to-the-pin contest
											</li>
											<li>
												Hit your best wedge shot into the iconic #7 at Pebble Beach (left and right-handed clubs will be
												available)
											</li>
											<li>Contest will run from 5:45-6:30</li>
										</ul>
									</li>
									<li>
										<b class="mb-2 block text-lg">Putting Contest:</b>
										<ul class="flex list-disc flex-col gap-1 ps-8">
											<li>
												It’s simple - make a putt to compete against the field to win one of 3 custom-fit Taylormade
												putters
											</li>
											<li>Contest will run from 5-6pm and the final will be held around 6:15pm</li>
											<li>Taylormade putter fitter will be on-site </li>
										</ul>
									</li>
									<li>
										<b class="mb-2 block text-lg">Food/Drink & Entertainment:</b>
										<ul class="flex list-disc flex-col gap-1 ps-8">
											<li>Each attendee will receive 2 complimentary drink tickets (cash bar after that)</li>
											<li>Food trucks will be on-site serving free Mexican and BBQ</li>
											<li>Music from DJ Munition</li>
										</ul>
									</li>
								</ul>
							</Section>
						</NoHydration>
					</Section>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
